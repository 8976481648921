
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ILesson from "@/types/lesson";
import ImageLoaderWithPreview from "@/components/ImageLoaderWithPreview.vue";
import UploadFileMixin from "@/mixins/upload-file.mixin";
import { VueEditor } from "vue2-editor";
import { baseMediaUrl } from "@/api/media";

@Component({
  components: { ImageLoaderWithPreview, VueEditor },
})
export default class VideoUploadInput extends mixins(
  UploadFileMixin
) {
  @Prop() private title?: string;
  @Prop() filename?: string;
  @Prop() handleUpload?: (filename: string) => void;
  @Prop() rules?: ((val: string) => string | boolean)[];

  private get fileLink() {
    return this.filename ? baseMediaUrl + this.filename : "";
  }

  @Watch("lesson", { deep: true })
  onLessonChange(lesson: ILesson): void {
    this.$emit("change", lesson);
  }

  handleLoadedFile(src: string): void {
    this.handleUpload?.(src)
  }
}
