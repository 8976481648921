import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c(VContainer,[_c('p',{staticClass:"text-h6"},[_vm._v("Тип события")]),_c(VSelect,{attrs:{"rules":[_vm.requiredRule],"label":"Тип","disabled":_vm.isEdit,"placeholder":"Выберите тип","items":_vm.typeOptions},on:{"change":_vm.onTypeChange},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),(_vm.data.type === 'info')?_c('event-information',{ref:"info",attrs:{"prefetched-data":_vm.prefetchedEvent && _vm.prefetchedEvent.info},on:{"change":function($event){_vm.data[_vm.data.type] = $event}}}):(_vm.data.type === 'hint')?_c('event-hint',{ref:"hint",attrs:{"prefetched-data":_vm.prefetchedEvent && _vm.prefetchedEvent.hint},on:{"change":function($event){_vm.data[_vm.data.type] = $event}}}):(_vm.data.type === 'anomaly')?_c('event-anomaly',{ref:"anomaly",attrs:{"prefetched-data":_vm.prefetchedEvent && _vm.prefetchedEvent.anomaly,"linkable-events":_vm.linkableEvents},on:{"change":function($event){_vm.data[_vm.data.type] = $event}}}):(_vm.data.type === 'quiz')?_c('event-test',{ref:"quiz",attrs:{"prefetched-data":_vm.prefetchedEvent && _vm.prefetchedEvent.quiz},on:{"change":function($event){_vm.data[_vm.data.type] = $event}}}):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Отмена ")]),_c(VBtn,{attrs:{"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submitTest)+" ")])],1),_c(VExpandTransition,[(_vm.errorMessage)?_c(VCardText,{staticClass:"base-error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }