
import { Component, Prop, Watch } from "vue-property-decorator";
import { IInfoEvent } from "@/types/events";
import { mixins } from "vue-class-component";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import UploadImageInput from "@/components/UploadImageInput.vue"

@Component({
  components: { UploadImageInput },
})
export default class EventInformation extends mixins(
  RequiredRuleMixin
) {
  @Prop() private readonly prefetchedData!: IInfoEvent | null;

  @Watch("prefetchedData", { immediate: true })
  private onPrefetchedEvent(): void {
    if (this.prefetchedData) {
      this.data.text = this.prefetchedData.text || "";
      this.data.image = this.prefetchedData.image || "";
      this.data.title = this.prefetchedData.title || "";
      this.data.isAnomalyEvent = this.prefetchedData.isAnomalyEvent || false;
      this.data.modalWidth = this.prefetchedData.modalWidth || "normal";
      this.data.timer = this.prefetchedData.timer || null;
    }
  }

  private textRule = (value: string) => {
    return (
      !!value || !!this.data.image || "Это поле обязательно для заполнения"
    );
  };

  private fileRule = () => {
    return (
      !!this.data.image ||
      !!this.data.text ||
      "Это поле обязательно для заполнения"
    );
  };

  private data: IInfoEvent = {
    title: "",
    text: "",
    image: null,
    isAnomalyEvent: false,
    modalWidth: "normal",
    timer: null,
  };

  handleLoadedFile(src: string): void {
    this.data.image = src;
    this.$emit("change", this.data);
  }

  private onInput() {
    this.$emit("change", this.data);
  }
}
