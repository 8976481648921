
import { Component, Prop, Watch } from "vue-property-decorator";
import { IQuizEvent, IQuizQuestion } from "@/types/events";
import { mixins } from "vue-class-component";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import UploadImageInput from "@/components/UploadImageInput.vue"
import cloneDeep from "lodash/cloneDeep";

@Component({
  components: { UploadImageInput },
})
export default class EventTest extends mixins(RequiredRuleMixin) {
  @Prop() private readonly prefetchedData!: IQuizEvent | null;

  @Watch("prefetchedData", { immediate: true })
  private onPrefetchedEvent(): void {
    if (this.prefetchedData) {
      const { title, questions, isAnomalyEvent, resultImage } = this.prefetchedData;

      this.title = title;
      this.isAnomalyEvent = isAnomalyEvent;
      this.questions = cloneDeep(questions);
    }
  }

  private title = "";
  private isAnomalyEvent = false;
  private questions: IQuizQuestion[] = [
    {
      text: "",
      isMulti: false,
      options: [
        {
          text: "",
          correct: false,
        },
      ],
    },
  ];
  private resultImage: string | null = null;

  private addNewQuestion() {
    this.questions.push({
      text: "",
      isMulti: false,
      options: [
        {
          text: "",
          correct: false,
        },
      ],
    });
  }

  private removeQuestionByIdx(idx: number) {
    this.questions = this.questions.filter((el, i) => i !== idx);
  }

  private addOption(idx: number) {
    const newQuestions = [...this.questions];
    const newQuestion = { ...newQuestions[idx] };

    newQuestion.options = [
      ...newQuestion.options,
      {
        text: "",
        correct: false,
      },
    ];
    newQuestions[idx] = newQuestion;

    this.questions = newQuestions;
  }

  private removeAnswerByIdx(questionIdx: number, answerIdx: number) {
    const newQuestions = [...this.questions];
    const newQuestion = { ...newQuestions[questionIdx] };

    newQuestion.options = newQuestion.options.filter(
      (el, idx) => idx !== answerIdx
    );

    newQuestions[questionIdx] = newQuestion;

    this.questions = newQuestions;
  }

  private onMultipleChange(isMultiple: boolean, questionIdx: number) {
    if (isMultiple) {
      return;
    }

    const newQuestions = [...this.questions];
    const newQuestion = { ...newQuestions[questionIdx] };

    newQuestion.options.forEach((el) => {
      el.correct = false;
    });

    this.questions = newQuestions;
  }

  private onAnswerCorrectChange(
    isCorrect: boolean,
    questionIdx: number,
    answerIdx: number
  ) {
    const { isMulti } = this.questions[questionIdx];

    if (isMulti || !isCorrect) {
      return;
    }

    const newQuestions = [...this.questions];
    const newQuestion = { ...newQuestions[questionIdx] };

    newQuestion.options.forEach((el) => {
      el.correct = false;
    });

    newQuestion.options[answerIdx].correct = isCorrect;

    this.questions = newQuestions;
  }

  @Watch("title")
  @Watch("questions", { deep: true })
  @Watch("isAnomalyEvent")
  @Watch("resultImage")
  onChange(): void {
    const quiz: IQuizEvent = {
      title: this.title,
      questions: this.questions,
      isAnomalyEvent: this.isAnomalyEvent,
      resultImage: this.resultImage,
    };
    this.$emit("change", quiz);
  }
  private questionsIncorrectValues: { [key: number]: boolean } = {};

  private validateCorrectness() {
    let res = true;
    const errors: { [key: number]: boolean } = {};
    this.questions.forEach((el, idx) => {
      const { options } = el;
      const hasSomeCorrect = options.some((o) => o.correct);
      errors[idx] = !hasSomeCorrect;
      res = res && hasSomeCorrect;
    });
    this.questionsIncorrectValues = errors;
    return res;
  }

  public validateForm(): boolean {
    if (!this.form) {
      return true;
    }

    let res = this.form.validate();
    return this.validateCorrectness() && res;
  }
}
