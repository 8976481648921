
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ImageLoaderWithPreview from "@/components/ImageLoaderWithPreview.vue";
import UploadFileMixin from "@/mixins/upload-file.mixin";
import { VueEditor } from "vue2-editor";
import { baseMediaUrl } from "@/api/media";

@Component({
  components: { ImageLoaderWithPreview, VueEditor },
})
export default class VideoUploadInput extends mixins(
  UploadFileMixin
) {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) filename!: string | undefined | null;
  @Prop({ required: true }) handleUpload!: (filename: string) => void;
  @Prop() rules?: (() => boolean | string)[]

  private get fileLink() {
    return this.filename ? baseMediaUrl + this.filename : "";
  }

  handleLoadedFile(src: string): void {
    this.handleUpload(src)
  }
}
