
import { Component, Prop, Watch } from "vue-property-decorator";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import { mixins } from "vue-class-component";
import ILesson, {
  lessonContentTypes,
  LessonContentTypes,
} from "@/types/lesson";
import ImageLoaderWithPreview from "@/components/ImageLoaderWithPreview.vue";
import UploadVideoInput from "@/components/UploadVideoInput.vue";
import { VueEditor } from "vue2-editor";

@Component({
  components: { ImageLoaderWithPreview, VueEditor, UploadVideoInput },
})
export default class LessonForm extends mixins(RequiredRuleMixin) {
  @Prop() private readonly prefetchedLesson!: ILesson | null;
  @Prop({ required: true }) private readonly isEdit!: boolean;

  videoLoaderError = "";
  markupVideoLoaderError = "";

  private lesson: ILesson = {
    anomalyHints: false,
    coverImage: "",
    description: "",
    name: "",
    video: "",
    markupVideo: "",
    index: 0,
    nextLessonID: 0,
    showRestartButton: true,
    lessonClickLimit: 0,
    anomalyFailSelectWarningAmount: 0,
    anomalyFailSelectWarningMessage: '',
    forbidExit: false,
  };

  get videoRules() {
    return this.isEdit ? [] : [this.requiredRule];
  }

  get numberRules() {

    const isInteger = (v: number) => Number.isInteger(v) || 'Введите целое число';
    const isPositive = (v: number) => v >= 0 || 'Введите положительное число';

    return [isInteger, isPositive];
  }

  @Watch("prefetchedLesson", { immediate: true })
  updateForm(newLesson: ILesson): void {
    if (newLesson) {
      this.lesson = { ...newLesson };
    }
  }

  @Watch("lesson", { deep: true })
  onLessonChange(lesson: ILesson): void {
    this.$emit("change", lesson);
  }
}
