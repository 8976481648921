
import { Component, Prop, Watch } from "vue-property-decorator";
import { IHintEvent } from "@/types/events";
import { mixins } from "vue-class-component";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";

@Component({
  components: {},
})
export default class EventHint extends mixins(RequiredRuleMixin) {
  @Prop() private readonly prefetchedData!: IHintEvent | null;

  @Watch("prefetchedData", { immediate: true })
  private onPrefetchedEvent(): void {
    if (this.prefetchedData) {
      this.data = { ...this.prefetchedData };
    }
  }

  private data: IHintEvent = {
    title: "",
    text: "",
  };

  private onInput() {
    this.$emit("change", this.data);
  }
}
