
import { Component, Ref, Vue } from "vue-property-decorator";
import LessonForm from "@/components/LessonForm.vue";
import ILesson from "@/types/lesson";
import { createLesson, deleteLesson, editLesson, getLesson } from "@/api/api";
import EventsBlock from "@/components/EventsBlock.vue";
import BackButton from "@/components/BackButton.vue";

@Component({
  components: { BackButton, EventsBlock, LessonForm },
})
export default class CreateLesson extends Vue {
  @Ref("form") private readonly form!: LessonForm;

  private isEditPage = false;
  private courseID = "";
  private lessonID = "";

  private isLessonLoading = false;
  private errorMessage = "";

  private prefetchedLesson: ILesson = {
    anomalyHints: false,
    coverImage: "",
    description: "",
    name: "",
    video: "",
    markupVideo: "",
    index: 0,
    nextLessonID: null,
    showRestartButton: true,
    lessonClickLimit: 0,
    anomalyFailSelectWarningAmount: 0,
    anomalyFailSelectWarningMessage: '',
    forbidExit: false,
  };

  private newLesson = { ...this.prefetchedLesson };
  private async getLesson(lessonId: string) {
    try {
      this.prefetchedLesson = {
        ...this.prefetchedLesson,
        ...(await getLesson(lessonId)),
      };
    } catch (e) {
      console.error(e);
    }
  }

  private get areFilesLoading() {
    return this.$store.getters["fileLoadModule/areFilesLoading"];
  }

  private get cancelPath() {
    return `/courses/${this.courseID}`;
  }

  mounted(): void {
    this.courseID = this.$route.params.courseId;
    this.lessonID = this.$route.params.lessonId;

    this.isEditPage = !!this.lessonID;

    if (!this.isEditPage) {
      return;
    }

    this.getLesson(this.lessonID);
  }

  private get title() {
    return `${this.isEditPage ? "Редактировать" : "Создать"} урок`;
  }

  private validateForm() {
    return this.form.validateForm() 
        && !this.form.videoLoaderError
        && !this.form.markupVideoLoaderError;
  }

  private async deleteLesson() {
    try {
      await deleteLesson(+this.prefetchedLesson.id!);

      await this.$router.push(`/courses/${this.courseID}`);
    } catch (e) {
      console.error(e);
    }
  }

  private async editLesson() {
    if (!this.validateForm()) {
      return;
    }
    this.isLessonLoading = true;

    try {
      await editLesson({
        ...this.newLesson,
        index: +this.newLesson.index,
        lessonID: +this.lessonID,
      });

      await this.$router.push(`/courses/${this.courseID}`);
    } catch (e) {
      console.error(e, e?.response?.data?.error);
      if (e?.response?.data?.error) {
        this.errorMessage = e.response?.data?.error;
      }
    } finally {
      this.isLessonLoading = false;
    }
  }

  private async createLesson(): Promise<void> {
    if (!this.validateForm()) {
      return;
    }
    this.isLessonLoading = true;

    try {
      const { LessonID } = await createLesson({
        ...this.newLesson,
        courseID: +this.courseID,
        index: +this.newLesson.index,
      });

      await this.$router.push(
        `/courses/${this.courseID}/lessons-create/${LessonID}`
      );

      if (!LessonID) {
        return;
      }

      this.lessonID = `${LessonID}`;
      this.isEditPage = true;
      this.errorMessage = "";
      this.prefetchedLesson = { ...this.newLesson, courseID: +this.courseID };
    } catch (e) {
      console.error(e);

      if (e?.response?.data?.error) {
        this.errorMessage = e.response?.data?.error;
      }
    } finally {
      this.isLessonLoading = false;
    }
  }

  private beforeDestroy() {
    this.$store.dispatch("fileLoadModule/removeAll");
  }
}
