
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import UploadImageInput from "@/components/UploadImageInput.vue"
import { IAnomalyEvent } from "@/types/events";
import { IEvent } from "@/types/create-event";

@Component({
  components: { UploadImageInput },
})
export default class EventAnomaly extends mixins(RequiredRuleMixin) {
  @Prop({ default: () => [] }) private readonly linkableEvents!: IEvent[];
  @Prop() private readonly prefetchedData!: IAnomalyEvent | null;

  @Watch("prefetchedData", { immediate: true })
  private onPrefetchedEvent(): void {
    if (this.prefetchedData) {
      this.data = { ...this.prefetchedData };
    }
  }

  private get selectOptions() {
    console.log(this.linkableEvents)
    const select = this.linkableEvents.map((e) => {
      switch (e.type) {
        case "quiz":
          return { text: e.quiz ? e.quiz.title : "", value: e.id };
        case "info":
          return { text: e.info ? e.info.title : "", value: e.id };
      }
    });
    console.log("select options", select)
    return select
  }

  // idk is this number or string, cant check
  private zIndexRule = (val: number | string) => {
    const valueToCheck = +val;

    if (Number.isNaN(valueToCheck)) {
      return "Введите валидное значение";
    }

    if (!Number.isInteger(valueToCheck)) {
      return "Введите целое значение";
    }

    if (valueToCheck <= 0) {
      return "Значение должно быть больше нуля";
    }

    return true
  };

  private data: IAnomalyEvent = {
    correct: true,
    name: "",
    linkedEventID: 0,
    zIndex: 1,
    resultImage: null
  };

  handleResultImageChange(filename: string) {
    this.data.resultImage = filename
    this.$emit("change", this.data);
  }

  onInput(): void {
    this.$emit("change", this.data);
  }
}
