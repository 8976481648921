
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEvent } from "@/types/create-event";
import { EventType, IInfoEvent, IQuizEvent } from "@/types/events";
import { deleteLessonEvent, setEventTime } from "@/api/api";
import CreateEventModal from "@/components/CreateEventModal.vue";
import { baseMediaUrl } from "@/api/media";

@Component({
  components: { CreateEventModal },
})
export default class EventCard extends Vue {
  @Prop({ required: true }) private readonly event!: IEvent;
  @Prop({ default: () => [] }) private readonly linkableEvents!: IEvent[];

  private mediaUrl = baseMediaUrl;

  private isDialogOpen = false;

  private loading = false;
  private onEdit(event: IEvent) {
    this.$emit("edit-event", event);
  }
  private get title() {
    const { type } = this.event;
    let humanType = "";

    if (type === "quiz") {
      humanType = "ТЕСТ";
    } else if (type === "anomaly") {
      humanType = "АНОМАЛИЯ";
    } else if (type === "hint") {
      humanType = "ПОДСКАЗКА";
    } else if (type === "info") {
      humanType = "ИНФОРМАЦИОННОЕ ОКНО";
    }

    const selectedEvent: any = (this.event as any)[type];

    if (!selectedEvent) {
      return "";
    }

    const name = selectedEvent.name || selectedEvent.title;
    return `${humanType}: ${name}`;
  }

  private get type(): EventType {
    return this.event.type as EventType;
  }

  private get quiz(): IQuizEvent | null {
    return this.event.quiz || null;
  }

  private get info(): IInfoEvent | null {
    return this.event.info || null;
  }
  
  width(w: 'narrow' | 'normal' | 'wide') {
    switch (w) {
      case 'narrow':
        return "Узкое"
      case 'normal':
        return "Нормальное"
      case 'wide':
        return "Широкое"
      default:
        return w
    }
  }

  private async updateTime() {
    if (this.event.id === undefined || this.event.time === undefined) {
      return;
    }
    try {
      this.loading = true
      await setEventTime(this.event.id, this.event.time)
      this.$emit("edit-event", this.event)
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  private async deleteEvent() {
    if (this.event.id === undefined) {
      return;
    }

    this.loading = true;

    try {
      await deleteLessonEvent(this.event.id);
      this.$emit("delete-event", this.event.id);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
