import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,{ref:"form",on:{"input":_vm.onInput}},[_c(VTextField,{attrs:{"name":"name","label":"Заголовок","required":"","counter":"44","rules":[_vm.requiredRule, _vm.getMaxLengthRule(44)]},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c(VTextarea,{attrs:{"name":"text","label":"Текст","required":"","rules":[_vm.requiredRule]},model:{value:(_vm.data.text),callback:function ($$v) {_vm.$set(_vm.data, "text", $$v)},expression:"data.text"}}),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"8","align":"center"}},[_c('p',[_vm._v(" Расположение подсказки вы сможете указать в приложении для разметки ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }