import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,{ref:"form",on:{"input":_vm.onInput}},[_c(VTextField,{attrs:{"rules":[_vm.requiredRule, _vm.getMaxLengthRule(200)],"name":"name","label":"Заголовок","counter":"200","required":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c(VSelect,{attrs:{"items":_vm.selectOptions,"placeholder":"Привязанный объект","label":"Привязанный объект","no-data-text":"Пока нет подходящих инфо или тестов","rules":[_vm.requiredRule]},model:{value:(_vm.data.linkedEventID),callback:function ($$v) {_vm.$set(_vm.data, "linkedEventID", $$v)},expression:"data.linkedEventID"}}),_c(VTextField,{attrs:{"items":_vm.selectOptions,"placeholder":"Слой аномалии (при наложении кликнется та, что выше)","label":"Слой аномалии (при наложении кликнется та, что выше)","rules":[_vm.zIndexRule, _vm.requiredRule]},model:{value:(_vm.data.zIndex),callback:function ($$v) {_vm.$set(_vm.data, "zIndex", _vm._n($$v))},expression:"data.zIndex"}}),_c(VRadioGroup,{model:{value:(_vm.data.correct),callback:function ($$v) {_vm.$set(_vm.data, "correct", $$v)},expression:"data.correct"}},[_c(VRadio,{attrs:{"value":true,"label":"Реальная аномалия"}}),_c(VRadio,{attrs:{"value":false,"label":"Ловушка"}})],1),_c('upload-image-input',{attrs:{"name":"resultImage","label":"Изображение в результате","filename":_vm.data.resultImage,"handle-upload":(filename) => _vm.data.resultImage = filename}}),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"8","align":"center"}},[_c('p',[_vm._v("Указать места аномалии вы сможете в приложении для разметки")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }