import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,{ref:"form",staticClass:"event-test"},[_c(VTextField,{attrs:{"rules":[_vm.requiredRule, _vm.getMaxLengthRule(300)],"name":"name","label":"Заголовок","required":"","counter":"300"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VRadioGroup,{model:{value:(_vm.isAnomalyEvent),callback:function ($$v) {_vm.isAnomalyEvent=$$v},expression:"isAnomalyEvent"}},[_c(VRadio,{attrs:{"value":false,"label":"Не для привязки к аномалии"}}),_c(VRadio,{attrs:{"value":true,"label":"Для привязки к аномалии"}})],1),_vm._l((_vm.questions),function(question,questionIdx){return _c(VCol,{key:questionIdx},[_c(VRow,{staticClass:"event-test__question"},[_c(VTextField,{attrs:{"placeholder":"Введите вопрос","label":"Вопрос","append-icon":"mdi-close","counter":"200","rules":[_vm.requiredRule, _vm.getMaxLengthRule(600)],"error":_vm.questionsIncorrectValues[questionIdx],"error-messages":_vm.questionsIncorrectValues[questionIdx]
            ? ['Выберите хотя бы один правильный ответ']
            : []},on:{"click:append":function($event){return _vm.removeQuestionByIdx(questionIdx)}},model:{value:(question.text),callback:function ($$v) {_vm.$set(question, "text", $$v)},expression:"question.text"}}),_c(VCheckbox,{attrs:{"label":"Множественный выбор"},on:{"change":function($event){return _vm.onMultipleChange($event, questionIdx)}},model:{value:(question.isMulti),callback:function ($$v) {_vm.$set(question, "isMulti", $$v)},expression:"question.isMulti"}})],1),_vm._l((question.options),function(a,answerIdx){return _c(VRow,{key:answerIdx,staticClass:"event-test__answer"},[_c(VTextField,{attrs:{"label":`Вариант ${answerIdx + 1}`,"append-icon":"mdi-close","rules":[_vm.requiredRule, _vm.getMaxLengthRule(600)],"counter":"200"},on:{"click:append":function($event){return _vm.removeAnswerByIdx(questionIdx, answerIdx)}},model:{value:(a.text),callback:function ($$v) {_vm.$set(a, "text", $$v)},expression:"a.text"}}),_c(VCheckbox,{attrs:{"label":"Верно"},on:{"change":function($event){return _vm.onAnswerCorrectChange($event, questionIdx, answerIdx)}},model:{value:(a.correct),callback:function ($$v) {_vm.$set(a, "correct", $$v)},expression:"a.correct"}})],1)}),(question.options.length < 10)?_c(VBtn,{staticClass:"event-test__answer mt-4",on:{"click":function($event){return _vm.addOption(questionIdx)}}},[_vm._v(" Добавить вариант ")]):_vm._e()],2)}),_c(VBtn,{on:{"click":_vm.addNewQuestion}},[_vm._v(" Добавить вопрос ")]),_c('upload-image-input',{attrs:{"name":"resultImage","label":"Изображение в результате","filename":_vm.resultImage,"handle-upload":(filename) => _vm.resultImage = filename}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }