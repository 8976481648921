
import { Component, Prop, Watch } from "vue-property-decorator";
import EventInformation from "@/components/EventInformation.vue";
import EventHint from "@/components/EventHint.vue";
import EventAnomaly from "@/components/EventAnomaly.vue";
import EventTest from "@/components/EventTest.vue";
import { ICreateEvent, IEvent } from "@/types/create-event";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import { mixins } from "vue-class-component";
import cloneDeep from "lodash/cloneDeep";
import { addEventLesson, editLessonEvent } from "@/api/api";

@Component({
  components: { EventTest, EventAnomaly, EventHint, EventInformation },
})
export default class CreateEventModal extends mixins(RequiredRuleMixin) {
  @Prop({ default: false }) private readonly dialog!: boolean;
  @Prop({ required: true }) private readonly lessonId!: number;
  @Prop({ default: () => [] }) private readonly linkableEvents!: IEvent[];
  @Prop({ default: null }) private readonly prefetchedEvent!: IEvent | null;

  private errorMessage = "";

  @Watch("dialog")
  private onPrefetchedEvent(): void {
    if (this.dialog && this.prefetchedEvent) {
      this.data = cloneDeep(this.prefetchedEvent);
    }
  }
  private get isEdit() {
    return !!this.prefetchedEvent;
  }

  private get title() {
    return this.isEdit ? "Редактировать событие" : "Создать событие";
  }

  private get submitTest() {
    return this.isEdit ? "Редактировать" : "Добавить";
  }

  private loading = false;
  private defaultFormValue: Omit<ICreateEvent, "lessonID"> = {
    type: "",
    anomaly: null,
    hint: null,
    quiz: null,
    info: {
      title: "",
      text: "",
      image: null,
      isAnomalyEvent: false,
      modalWidth: "normal",
      timer: null,
    },
  };

  private data = { ...this.defaultFormValue };

  private typeOptions = [
    { text: "Информационное окно", value: "info" },
    { text: "Тест", value: "quiz" },
    { text: "Аномалия", value: "anomaly" },
  ];

  private onTypeChange(): void {
    this.data = {
      ...this.data,
      anomaly: null,
      hint: null,
      quiz: null,
      info: null,
    };
  }

  private closeModal() {
    this.$emit("close");
    this.data = { ...this.defaultFormValue };
    this.errorMessage = "";
  }

  private submit() {
    const ref = this.$refs[this.data.type];

    if (!this.validateForm()) {
      return;
    }

    const isValid = (ref as RequiredRuleMixin).validateForm();

    if (!isValid) {
      return;
    }

    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  private async edit() {
    console.log(this.data)
    if (!this.prefetchedEvent) {
      return;
    }

    const { type } = this.data;

    const newEvent = type === "" ? null : cloneDeep(this.data[type]);
    if (!newEvent) {
      return;
    }
    this.loading = true;

    try {
      const createData: IEvent = {
        [type]: newEvent,
        type,
        id: this.prefetchedEvent.id,
        eventID: this.prefetchedEvent.id,
      };

      await editLessonEvent(createData);
      this.$emit("edit-event", {
        ...createData,
      });

      this.closeModal();
    } catch (e) {
      this.errorMessage =
        e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  private async add() {
    const { type } = this.data;

    const newEvent = type === "" ? null : cloneDeep(this.data[type]);
    if (!newEvent) {
      return;
    }
    this.loading = true;

    try {
      const createData: ICreateEvent = {
        [type]: newEvent,
        type,
        lessonID: +this.lessonId,
      };
      const { eventID } = await addEventLesson(createData);

      this.$emit("save-event", {
        ...createData,
        id: eventID,
      });

      this.closeModal();
    } catch (e) {
      console.error(e);
      this.errorMessage =
        e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
    } finally {
      this.loading = false;
    }
  }
}
