
import { Component, Prop, Vue } from "vue-property-decorator";
import CreateEventModal from "@/components/CreateEventModal.vue";
import { IEvent } from "@/types/create-event";
import { getLessonEvent } from "@/api/api";
import EventCard from "@/components/EventCard.vue";
import TheLoader from "@/components/TheLoader.vue";

@Component({
  components: { TheLoader, EventCard, CreateEventModal },
})
export default class EventsBlock extends Vue {
  @Prop({ required: true }) private readonly lessonId!: string;
  private loading = true;
  private isCreateModalOpen = false;
  private canRunVR = false;

  private events: IEvent[] = [];
  private addEvent(event: IEvent) {
    this.events.push(event);
  }

  private editEvent(event: IEvent) {
    const idx = this.events.findIndex(({ id }) => id === event.eventID);

    if (idx === -1) {
      return;
    }

    const newEvents = [...this.events];
    newEvents[idx] = { ...event };
    this.events = newEvents;
  }

  private async getEvents(): Promise<void> {
    try {
      this.events = await getLessonEvent(this.lessonId);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  private get linkableEvents() {
    const events = this.events.filter(
      (event) => {
        switch (event.type) {
          case "info":
            return event.info?.isAnomalyEvent
          case "quiz":
            return event.quiz?.isAnomalyEvent
          default:
            return false
        }
      }
    );
    return events
  }

  private onDeleteEvent(eventId: number) {
    this.events = this.events.filter(({ id }) => id !== eventId);
  }

  private onMarkup() {
    window.location.replace(`/vr/?lessonID=${this.lessonId}`);
  }

  async mounted(): Promise<void> {
    this.canRunVR = await ("xr" in window.navigator &&
      (<XRSystem>window.navigator.xr).isSessionSupported("immersive-vr"));
    await this.getEvents();
  }
}
